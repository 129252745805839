import {Component, Input} from '@angular/core'
import {FormGroup} from '@angular/forms'
import {booleanOptions} from '../../../types'

@Component({
  selector: 'spb-bank-relationship',
  templateUrl: './bank-relationship.component.html',
  styleUrl: '../kyc.component.scss'
})
export class BankRelationshipComponent {
  @Input() bankRelationshipForm!: FormGroup

  public interestRunningTimeOptions = [
    {value: 3, label: '3 månader'},
    {value: 6, label: '6 månader'},
    {value: 12, label: '1 år'},
    {value: 18, label: '1,5 år'},
    {value: 24, label: '2 år'},
    {value: 36, label: '3 år'},
    {value: 48, label: '4 år'},
    {value: 60, label: '5 år'}
  ]

  protected readonly yesOrNo = booleanOptions

  public onCheckboxChange(value: number | boolean, control: string) {
    this.bankRelationshipForm.patchValue({[control]: value})
  }
}
