import {Component, Input} from '@angular/core'
import {FormGroup} from '@angular/forms'

@Component({
  selector: 'spb-green-card',
  templateUrl: './green-card.component.html',
  styleUrl: '../kyc.component.scss'
})
export class GreenCardComponent {
  @Input({required: true}) greenCardForm!: FormGroup
}
