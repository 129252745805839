<div class="header">
  <!-- Router link here is default ["/"] -->
  <spb-logo></spb-logo>
  <div [style.flex-grow]="1"></div>
  @if (!prod) {
    <button>
    <mat-checkbox
      color="primary"
      (change)="savingsAccountService.testMode$.set($event.checked)">
      Utvecklare (bara i testmiljö)
    </mat-checkbox>
    </button>
  }
</div>