import {Component, Input} from '@angular/core'
import {FormGroup} from '@angular/forms'

@Component({
  selector: 'spb-american-citizenship',
  templateUrl: './american-citizenship.component.html',
  styleUrl: '../kyc.component.scss'
})
export class AmericanCitizenshipComponent {
  @Input({required: true}) americanCitizenshipForm!: FormGroup
}
