import {Component, Input} from '@angular/core'
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms'
import {OrientationService} from '../../../services/orientation.service'
import {countryList} from '../../../types'

@Component({
  selector: 'spb-birth',
  templateUrl: './birth.component.html',
  styleUrl: '../kyc.component.scss'
})
export class BirthComponent {
  @Input({required: true}) birthForm!: FormGroup
  protected readonly countryList = countryList

  constructor(protected orientationService: OrientationService) {}

  get citizenshipControls(): FormControl[] {
    return (this.birthForm.get('citizenship') as FormArray).controls as FormControl[]
  }

  public createCitizenship(): FormControl {
    return new FormControl(null, {
      nonNullable: true,
      validators: [Validators.required]
    })
  }

  public addCitizenship() {
    const citizenshipArray = this.birthForm.get('citizenship') as FormArray
    citizenshipArray.push(this.createCitizenship())
  }

  /**
   * I don't why, but I can't get this to work exactly as it should
   * (it always removes the last in the array)
   * @param index
   */
  public removeCitizenship(index: number) {
    const citizenshipArray = this.birthForm.get('citizenship') as FormArray
    citizenshipArray.removeAt(index)
  }
}
