<div class="summary-holder">

  @if (signed) {
    <h3>Signering pågår...</h3>
  } @else {
    @if (!accountApplication.id) {
      <h3>Godkännande av information</h3>
      <p>Du har nu fyllt i alla frågor</p>
      <p>Genom att godkänna uppgifterna intygar du att dessa är riktiga och
        sanningsenliga. Du förbinder dig att meddela banken om det skulle
        inträffa förändringar i dessa förhållanden.</p>
      <button mat-raised-button (click)="put()">Klicka här för att godkänna
      </button>
    } @else {
      <h3>BankID-signering</h3>
      <p>Genom underskriften intygar jag att lämnade uppgifter i ansökan är
        riktiga och sanningsenliga. Jag förbinder mig att meddela banken om det
        skulle inträffa förändringar i dessa förhållanden.</p>
      <spb-bankid
        inline
        mode="sign"
        [bankIdUrl]="requestApi"
        [collectUrl]="authServiceUrl"
        [userRef]="accountApplication.id"
        (accessToken)="submitSignature($event)"
      >
      </spb-bankid>
    }
  }
</div>
