<h3>Person i politiskt utsatt ställning (PEP)</h3>

<p class="select-title">Har du ett yrke som gör att du är en person i politiskt
  utsatt ställning
  (PEP) eller är du anhörig alternativt nära medarbetare till någon i politiskt
  utsatt ställning (RCA)?</p>

<form [formGroup]="pepForm">
  <div class="kyc-content-holder">

    <mat-radio-group
      color="primary"
      formControlName="pepStatus">
      @for (option of pepStatus; track $index) {
        <mat-radio-button
          [value]="option.value">
          {{ option.label }}
        </mat-radio-button>
      }
    </mat-radio-group>
  </div>


  @if (pepForm.get('pepStatus')!.value === 'pep' ||
  pepForm.get('pepStatus')!.value === 'rca') {
    <div class="kyc-content-holder column">
      <p class="select-title">Vänligen specificera arbetstiteln för
        personen i politiskt utsatt ställning</p>
      @for (option of pepTitles; track $index) {
        <mat-checkbox
          color="primary"
          [checked]="option.value === pepForm.controls.pepTitle.value"
          (change)="onCheckboxChange(option.value, 'pepTitle')">
          {{ option.label }}
        </mat-checkbox>
      }
    </div>


      <mat-form-field>
        <mat-label>Ange befattning och organisation</mat-label>
        <input matInput formControlName="pepPosition"
               placeholder="Fritext">
      </mat-form-field>


    <div>
      <mat-form-field>
        <mat-label>Välj land</mat-label>
        <mat-select
          placeholder="Välj land"
          formControlName="pepCountry"
          ngDefaultControl>
          @for (country of countryList; track $index) {
            <mat-option
              [ngClass]="{'separator-after': $index === 0}"
              [value]="country.name">{{ country.name }}
            </mat-option>
          }
        </mat-select>
        <mat-error>Vänligen gör ett val</mat-error>
      </mat-form-field>
    </div>
  }
  @if (pepForm.get('pepStatus')!.value === 'rca') {
    <div class="column">
      <p class="select-title">Vilken relation har du till personen i
        politiskt utsatt
        ställning?</p>
      @for (option of rcaRelations; track $index) {
        <mat-checkbox
          color="primary"
          [checked]="option.value === pepForm.controls.pepRelation.value"
          (change)="onCheckboxChange(option.value, 'pepRelation')">
          {{ option.label }}
        </mat-checkbox>
      }
    </div>
  }
</form>