import {HttpClient, HttpHeaders} from '@angular/common/http'
import {Injectable, signal, WritableSignal} from '@angular/core'
import {
  AccountApplication,
  AccountRequest, ApplicantData
} from '@sparbanken-syd/spb-savings-account-backend'
import {Observable} from 'rxjs'
import {environment} from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class SavingsAccountService {
  public testMode$: WritableSignal<boolean> = signal(false)

  public applicant$: WritableSignal<ApplicantData> = signal({
    name: '', personalNumber: '', countryCode: '', email: '', phone: ''
  })

  constructor(private httpClient: HttpClient) {
  }

  public saveRequest(request: AccountRequest): Observable<AccountApplication> {
    const url = `${environment.requestApi}/request`
    return this.httpClient.put<AccountApplication>(url, request)
  }

  /**
   * Add signature, the only thing we need is the access token.
   * @param accessToken - The token received after sign
   * @param id
   */
  public addSignature(accessToken: string, id: string | null): Observable<AccountApplication> {
    const url = `${environment.requestApi}/user/${id}/sign`
    let headers = new HttpHeaders()
    headers = headers.set('Authorization', `Bearer ${accessToken}`)
    return this.httpClient.put<AccountApplication>(url, {}, {headers})
  }

}
