<form [formGroup]="greenCardForm">
  <h3>Är du innehavare av amerikanskt uppehålls- och arbetstillstånd? (s.k. Green card)</h3>
    <p class="select-title">Vänligen välj:</p>
    <mat-radio-group
      color="primary"
      class="column"
      formControlName="greenCard">
      <mat-radio-button [value]="true">Ja</mat-radio-button>
      <mat-radio-button [value]="false">Nej</mat-radio-button>
    </mat-radio-group>
</form>
