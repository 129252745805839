<form [formGroup]="bankRelationshipForm">
  <h3>Min relation till Sparbanken Syd</h3>

  <div class="kyc-content-holder">
    <p class="select-title">Jag vill öppna ett sparkonto</p>
    <mat-checkbox
      color="primary"
      class="column"
      formControlName="openSavingsAccount">Ja
    </mat-checkbox>
  </div>

  <div class="kyc-content-holder">
    <p class="select-title">Jag vill göra en fastränteplacering med
      löptid</p>
    <div class="column">
      @for (option of interestRunningTimeOptions; track $index) {
        <mat-checkbox
          color="primary"
          [checked]="option.value === bankRelationshipForm.controls.interestRunningTime.value"
          (change)="onCheckboxChange(option.value, 'interestRunningTime')">
          {{ option.label }}
        </mat-checkbox>
      }
    </div>
  </div>

  <mat-form-field>
    <mat-label>Fastränteplacering med belopp</mat-label>
    <input placeholder="Fritext" formControlName="interestPlacementAmount"
           matInput type="text">
  </mat-form-field>

  <div class="kyc-content-holder">
    <p class="select-title">Utöver öppna ett sparkonto eller
      fastränteplacering vill jag även bli kontaktad för att bli informerad om
      bankens vardagstjänster och övriga produkter. </p>
    <div
      color="primary"
      class="column">
      @for (option of yesOrNo; track $index) {
        <mat-checkbox
          color="primary"
          [checked]="option.value === bankRelationshipForm.controls.wantToBeContacted.value"
          (change)="onCheckboxChange(option.value,'wantToBeContacted')">
          {{ option.label }}
        </mat-checkbox>
      }
    </div>
  </div>

</form>