import {Component, Input} from '@angular/core'
import {FormGroup} from '@angular/forms'
import {OrientationService} from '../../../services/orientation.service'
import {countryList} from '../../../types'

@Component({
  selector: 'spb-primary-residence',
  templateUrl: './primary-residence.component.html',
  styleUrl: '../kyc.component.scss'
})
export class PrimaryResidenceComponent {
  @Input({required: true}) primaryResidenceForm!: FormGroup

  protected readonly countryList = countryList
  constructor(protected orientationService: OrientationService) {
  }
}
