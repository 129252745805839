import {Component, Input} from '@angular/core'
import {FormGroup} from '@angular/forms'

@Component({
  selector: 'spb-income',
  templateUrl: './income.component.html',
  styleUrl: '../kyc.component.scss'
})
export class IncomeComponent {
  @Input({required: true}) incomeForm!: FormGroup

  public incomeOptions = [
    {value: 'salary', label: 'Lön', checked: false},
    {value: 'pension', label: 'Pension', checked: false},
    {
      value: 'benefits',
      label: 'Bidrag eller arbetslöshetsersättning',
      checked: false
    },
    {
      value: 'studentAid',
      label: 'Studiebidrag och/eller studielån',
      checked: false
    },
    {
      value: 'soleProprietorship',
      label: 'Inkomst från enskild firma',
      checked: false
    },
    {value: 'capital', label: 'Kapital', checked: false},
    {value: 'other', label: 'Annan', checked: false}
  ]

  public onCheckboxChange(index: number) {
    const option = this.incomeOptions[index]
    option.checked = !option.checked
  }


}
