import {Component, Input, OnInit} from '@angular/core'
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms'
import {OrientationService} from '../../../services/orientation.service'
import {countryList} from '../../../types'
import {FormUtils} from '../../../utils/form.utils'

@Component({
  selector: 'spb-tax-residency',
  templateUrl: './tax-residency.component.html',
  styleUrl: '../kyc.component.scss'
})
export class TaxResidencyComponent implements OnInit {
  @Input({required: true}) taxResidencyForm!: FormGroup

  public workingAbroadOptions = [
    {value: 'workingAbroad', label: 'Arbetar utomlands'},
    {value: 'propertyAbroad', label: 'Fastighet eller lägenhet i utlandet'},
    {value: 'other', label: 'Annan'},
    {value: 'none', label: 'Ingen'}
  ]

  protected readonly countryList = countryList
    .filter(country => country.name !== 'Sverige')

  constructor(protected orientationService: OrientationService) {
  }

  get controls(): FormControl[] {
    return (this.taxResidencyForm
      .get('taxResidencyCountries') as FormArray).controls as FormControl[]
  }

  public ngOnInit() {
    this.taxResidencyForm.controls.taxResidencyNotSwedish.valueChanges
      .subscribe({
        next: notSwedishTax => {
          const list = ['taxResidencyRelation', 'taxResidencyTin']
          const controls = this.taxResidencyForm.controls

          if (notSwedishTax) {
            /**
             * Add controls
             */
            list.forEach(l => {
              FormUtils.addOrRemoveValidation(true, controls[l], [Validators.required])
            })

            const countries = controls['taxResidencyCountries'] as FormArray
            countries.controls.forEach(control => {
              FormUtils.addOrRemoveValidation(true, control, [Validators.required])
            })
          } else {
            /**
             * Remove controls
             */
            list.forEach(l => {
              FormUtils.addOrRemoveValidation(false, controls[l], [Validators.required])
            })

            const countries = controls['taxResidencyCountries'] as FormArray
            countries.controls.forEach(control => {
              FormUtils.addOrRemoveValidation(false, control, [Validators.required])
            })
          }
        }
      })
  }

  public onCheckboxChange(value: string, control: string) {
    this.taxResidencyForm.patchValue({[control]: value})
  }

  public createControl(): FormControl {
    return new FormControl(null, {
      nonNullable: true,
      validators: [Validators.required]
    })
  }

  public addControl() {
    const array = this.taxResidencyForm
      .get('taxResidencyCountries') as FormArray
    array.push(this.createControl())
  }

  public removeControl(index: number) {
    const array = this.taxResidencyForm
      .get('taxResidencyCountries') as FormArray
    array.removeAt(index)
  }
}
