import {Component, Input, OnInit} from '@angular/core'
import {FormGroup} from '@angular/forms'
import {Router} from '@angular/router'
import {AccountApplication} from '@sparbanken-syd/spb-savings-account-backend'
import {filter} from 'rxjs'
import {environment} from '../../../environments/environment'
import {SavingsAccountService} from '../../services/savings-account.service'

@Component({
  selector: 'spb-summary',
  templateUrl: './summary.component.html'
})
export class SummaryComponent implements OnInit {
  @Input({required: true}) savingsAccountForm!: FormGroup
  public accountApplication = {id: ''} as AccountApplication
  public signed: string | null = null
  protected readonly requestApi = environment.requestApi
  protected readonly authServiceUrl = environment.authServiceUrl

  constructor(
    private savingsAccountService: SavingsAccountService, private router: Router
  ) {

  }

  public ngOnInit() {
    this.savingsAccountForm.valueChanges.pipe(
      filter(() => this.savingsAccountForm.valid)
    ).subscribe(() => this.accountApplication.id = ''
    )
  }

  public put() {
    const request = this.savingsAccountForm.value
    request.form = this.flattenForm()
    this.savingsAccountService.saveRequest(request).subscribe({
      next: (accountApplication) => {
        this.accountApplication = accountApplication
      }
    })
  }

  //TODO: This is ofc not the way to do it, but just to do something...
  //Should we save the data as it is now (and change in sk admin) or flatten the data some way?
  public flattenForm = () => {
    const forms = ['birthForm', 'primaryResidenceForm', 'greenCardForm', 'americanCitizenshipForm', 'taxResidencyForm', 'pepForm', 'bankRelationshipForm', 'incomeForm', 'savingsForm']

    const output = {}

    forms.forEach(formName => {
      const form = this.savingsAccountForm.controls.form.get(formName)?.value
      Object.entries(form).forEach(([key, value]) => {
        if (value !== null) {
          output[key] = value
        }
      })
    })

    return output
  }

  public submitSignature(token: string) {
    this.savingsAccountService.addSignature(token, this.accountApplication.id)
      .subscribe({
        next: () => void this.router.navigate(['/complete'],
          {state: {accountApplication: this.accountApplication}})
      })
    this.accountApplication.id = ''
    this.signed = token
  }
}
