import {Component, OnInit, ViewChild} from '@angular/core'
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms'
import {MatStepper} from '@angular/material/stepper'
import {phoneValidator} from '../../directives/PhoneValidator'
import {OrientationService} from '../../services/orientation.service'
import {SavingsAccountService} from '../../services/savings-account.service'

@Component({
  selector: 'spb-form',
  templateUrl: './form.component.html',
  styleUrl: './form.component.scss'
})
export class FormComponent implements OnInit {
  @ViewChild('stepper') stepper!: MatStepper

  public welcomeForm: FormGroup = new FormGroup({
    terms: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [Validators.requiredTrue]
    })
  })

  public applicantsForm: FormGroup = new FormGroup({
    personalNumber: new FormControl<string | null>(null, {
      nonNullable: true
    }),
    name: new FormControl<string | null>(null, {
      nonNullable: true
    }),
    streetAdress: new FormControl<string | null>(null, {
      nonNullable: true
    }),
    streetNumber: new FormControl<string | null>(null, {
      nonNullable: true
    }),
    postalCode: new FormControl<string | null>(null, {
      nonNullable: true
    }),
    city: new FormControl<string | null>(null, {
      nonNullable: true
    }),
    countryCode: new FormControl<string | null>(null, {
      nonNullable: true,
      validators: [Validators.required]
    }),
    phone: new FormControl<string | null>(null, {
      nonNullable: true,
      validators: [Validators.required, phoneValidator]
    }),
    email: new FormControl<string | null>(null, {
      nonNullable: true,
      validators: [Validators.email, Validators.required]
    })
  })


  public birthForm: FormGroup = new FormGroup({
    birthCountry: new FormControl<string | null>(null, {
      nonNullable: true,
      validators: [Validators.required]
    }),
    birthCity: new FormControl<string | null>(null, {
      nonNullable: true,
      validators: [Validators.required]
    }),
    citizenship: new FormArray([
      new FormControl(null, {
        nonNullable: true,
        validators: [Validators.required]
      })
    ])
  })

  public primaryResidenceForm: FormGroup = new FormGroup({
    primaryResidence: new FormControl<string | null>(null, {
      nonNullable: true,
      validators: [Validators.required]
    })
  })

  public greenCardForm: FormGroup = new FormGroup({
    greenCard: new FormControl<boolean | null>(null, {
      nonNullable: true,
      validators: [Validators.required]
    })
  })

  public americanCitizenshipForm: FormGroup = new FormGroup({
    americanCitizenship: new FormControl<boolean | null>(null, {
      nonNullable: true,
      validators: [Validators.required]
    })
  })

  public taxResidencyForm: FormGroup = new FormGroup({
    taxResidencyNotSwedish: new FormControl<boolean | null>(null, {
      nonNullable: true,
      validators: [Validators.required]
    }),
    taxResidencyCountries: new FormArray([
      new FormControl(null, {
        nonNullable: true,
        validators: [Validators.required]
      })
    ]),
    taxResidencyTin: new FormControl<string | null>(null, {
      nonNullable: true,
      validators: []
    }),
    taxResidencyRelation: new FormControl<string | null>(null, {
      nonNullable: true,
      validators: []
    })
  })

  public pepForm: FormGroup = new FormGroup({
    pepStatus: new FormControl<string | null>(null, {
      nonNullable: true,
      validators: [Validators.required]
    }),
    pepTitle: new FormControl<string | null>(null, {
      nonNullable: true,
      validators: [Validators.required]
    }),
    pepPosition: new FormControl<string | null>(null, {
      nonNullable: true,
      validators: [Validators.required]
    }),
    pepCountry: new FormControl<string | null>(null, {
      nonNullable: true,
      validators: [Validators.required]
    }),
    pepRelation: new FormControl<string | null>(null, {
      nonNullable: true,
      validators: [Validators.required]
    })
  })

  public bankRelationshipForm: FormGroup = new FormGroup({
    openSavingsAccount: new FormControl<boolean>(true, {
      nonNullable: true,
      validators: [Validators.requiredTrue]
    }),
    interestRunningTime: new FormControl<number | null>(null, {
      nonNullable: true,
      validators: [Validators.required]
    }),
    interestPlacementAmount: new FormControl<string | null>(null, {
      nonNullable: true
    }),
    wantToBeContacted: new FormControl<boolean | null>(null, {
      nonNullable: true,
      validators: [Validators.required]
    })
  })

  public incomeForm: FormGroup = new FormGroup({
    salary: new FormControl<number | null>(null, {nonNullable: true}),
    pension: new FormControl<number | null>(null, {nonNullable: true}),
    benefits: new FormControl<number | null>(null, {nonNullable: true}),
    studentAid: new FormControl<number | null>(null, {nonNullable: true}),
    soleProprietorship: new FormControl<number | null>(null, {nonNullable: true}),
    capital: new FormControl<number | null>(null, {nonNullable: true}),
    other: new FormControl<number | null>(null, {nonNullable: true})
  })

  public savingsForm: FormGroup = new FormGroup({
    savingsTotal: new FormControl<number | null>(null, {
      nonNullable: true,
      validators: [Validators.required]
    }),
    savingsYearly: new FormControl<number | null>(null, {
      nonNullable: true,
      validators: []
    }),
    savingsGoal: new FormControl<string | null>(null, {
      nonNullable: true,
      validators: []
    })
  })


  public kycForm: FormGroup = new FormGroup({
    birthForm: this.birthForm,
    primaryResidenceForm: this.primaryResidenceForm,
    greenCardForm: this.greenCardForm,
    americanCitizenshipForm: this.americanCitizenshipForm,
    taxResidencyForm: this.taxResidencyForm,
    pepForm: this.pepForm,
    bankRelationshipForm: this.bankRelationshipForm,
    incomeForm: this.incomeForm,
    savingsForm: this.savingsForm
  })


  public savingsAccountForm: FormGroup = new FormGroup({
    welcomeForm: this.welcomeForm,
    applicantData: this.applicantsForm,
    form: this.kycForm
  })

  constructor(
    protected savingsAccountService: SavingsAccountService,
    protected orientationService: OrientationService
  ) {
  }

  public ngOnInit() {
    this.applicantsForm.patchValue(this.savingsAccountService.applicant$())
  }

  public test() {
    console.log(this.kycForm.value)
  }
}
