import {Component, Input, OnInit} from '@angular/core'
import {FormGroup, Validators} from '@angular/forms'
import {countryList} from '../../../types'
import {FormUtils} from '../../../utils/form.utils'

@Component({
  selector: 'spb-pep',
  templateUrl: './pep.component.html',
  styleUrl: '../kyc.component.scss'
})
export class PepComponent implements OnInit {
  @Input() pepForm!: FormGroup

  public pepStatus = [
    {value: 'notPep', label: 'Nej, jag är inte PEP eller RCA'},
    {
      value: 'pep',
      label: 'Ja, jag är eller har de senaste 18 månaderna varit en person i politiskt utsatt ställning'
    },
    {
      value: 'rca',
      label: 'Ja, jag är anhörig alternativt nära medarbetare till någon som är, eller de senaste 18 månaderna varit, en person i politiskt utsatt ställning (RCA)'
    }
  ]
  public pepTitles = [
    {
      value: 'headOfGovernment',
      label: 'Stats-, eller regeringschef, minister eller biträdande minister'
    },
    {
      value: 'parliamentMember',
      label: 'Riksdagsledamot/Parlamentsledamot eller ledamot i centralbankens styrande organ'
    },
    {
      value: 'politicalPartyBoardMember',
      label: 'Ledamot i styrelse för politiskt parti'
    },
    {
      value: 'judgeOrLegalOfficial',
      label: 'Domare i Högsta domstolen, konstitutionell domstol eller annat rättsligt organ'
    },
    {
      value: 'governmentAuditor',
      label: 'Hög tjänsteman vid revisionsmyndighet'
    },
    {value: 'ambassadorOrEnvoy', label: 'Ambassadör eller beskickningschef'},
    {
      value: 'seniorMilitaryOfficer',
      label: 'Hög officerare inom Försvarsmakten'
    },
    {
      value: 'stateOwnedCompanyDirector',
      label: 'VD eller styrelseledamot i ett statsägt företag'
    },
    {
      value: 'internationalOrganizationLeader',
      label: 'Ledande befattning i en internationell organisation'
    }
  ]
  public rcaRelations = [
    {value: 'spouse', label: 'Make/maka'},
    {value: 'registeredPartner', label: 'Registrerad partner'},
    {value: 'cohabitant', label: 'Sambo'},
    {value: 'child', label: 'Barn'},
    {
      value: 'childSpouseCohabitant',
      label: 'Barns make/maka, sambo, registrerad partner är en PEP'
    },
    {value: 'parent', label: 'Förälder'},
    {value: 'knownCloseAssociate', label: 'Känd/Nära medarbetare'},
    {
      value: 'closeBusinessAssociate',
      label: 'Nära affärsförbindelse eller bestämmande inflytande i ett företag som ägs eller har bildats till förmån för en PEP'
    }
  ]
  protected readonly countryList = countryList

  public onCheckboxChange(value: string, control: string) {
    this.pepForm.patchValue({[control]: value})
  }

  public ngOnInit() {
    this.pepForm.controls.pepStatus.valueChanges
      .subscribe({
        next: status => {
          const list = ['pepTitle', 'pepPosition', 'pepCountry', 'pepRelation']
          const controls = this.pepForm.controls

          /**
           * Add controls
           */
          if (status === 'rca') {
            list.forEach(l => {
              FormUtils.addOrRemoveValidation(true, controls[l], [Validators.required])
            })
          } else if (status === 'pep') {
            const list = ['pepTitle', 'pepPosition', 'pepCountry']
            list.forEach(l => {
              FormUtils.addOrRemoveValidation(true, controls[l], [Validators.required])
            })
          } else {
            /**
             * Remove controls
             */
            list.forEach(l => {
              FormUtils.addOrRemoveValidation(false, controls[l], [Validators.required])
            })
          }
        }
      })
  }
}
