@if (savingsAccountService.testMode$() || savingsAccountService.applicant$().personalNumber) {
  <spb-form></spb-form>
} @else {
  <div class="center-ftw">
    <div class="text-container mat-elevation-z8">
      <h3>Vad roligt att du vill bli kund hos Sparbanken Syd!</h3>
      <p>För att bli kund behöver du ha ett BankID.</p>
      <p>Saknar du ett BankID är du välkommen att besöka ett av våra kontor för
        att bli kund.</p>
      <p>Vänligen identifiera dig för att påbörja din ansökan.</p>

      <div class="bankid-buttons">
        <spb-bankid
          mode="login"
          [bankIdUrl]="authServiceUrl"
          (accessToken)="setToken($event)"
        ></spb-bankid>
      </div>
    </div>
  </div>
}


