<form [formGroup]="incomeForm">
  <h3>Min ekonomi - Årlig inkomst</h3>
  <p>Hur mycket uppskattar du kommer att sättas in på ditt sparkonto hos oss
    närmsta året?</p>

  <div class="column flex-start">
    @for (option of incomeOptions; track $index) {
      <mat-checkbox
        color="primary"
        (change)="onCheckboxChange($index)"
        [style.margin-bottom.px]="12"
      >
        {{ option.label }}
      </mat-checkbox>

      @if (option.checked) {
        <mat-form-field>
          <mat-label>Ange årligt belopp i SEK (Lön, efter skatt)</mat-label>
          <input
            spbFormatNumber
            [formControlName]="option.value" matInput type="text">
          <mat-error>Vänligen ange födelseort</mat-error>
        </mat-form-field>
      }
    }
  </div>
</form>
