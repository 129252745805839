import {Component, Input} from '@angular/core'
import {AbstractControl, FormGroup} from '@angular/forms'
import {OrientationService} from '../../services/orientation.service'
import {countryCodes} from '../../types'

const formatPhone = (phone: string): string => {
  // Remove all non-digit characters
  const onlyDigits = phone.replace(/\D/g, '')

  // Check if the phone number is at least 9 digits long after removing non-digit characters
  if (onlyDigits.length < 9) {
    return onlyDigits // Return the original number if it's too short
  }

  // Extract the last nine digits
  const lastNineDigits = onlyDigits.slice(-9)

  // Insert a dash after the first three digits
  return lastNineDigits.substring(0, 3) + '-' + lastNineDigits.substring(3)
}

@Component({
  selector: 'spb-applicants',
  templateUrl: './applicants.component.html',
  styleUrl: './applicants.component.scss'
})
export class ApplicantsComponent {
  @Input({required: true}) applicantsForm!: FormGroup
  protected readonly countryCodeList = countryCodes

  constructor(protected orientationService: OrientationService) {
  }

  public formatPhone(control: AbstractControl) {
    control.setValue(formatPhone(control.value))
  }
}
