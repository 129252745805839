<form [formGroup]="savingsForm">
  <h3>Min ekonomi - Sparande</h3>
  <p>Hur stort är ditt totala sparade kapital i andra banker/institut?</p>
  <mat-form-field>
    <mat-label>Totalt sparat kapital, belopp i SEK</mat-label>
    <input matInput formControlName="savingsTotal" spbFormatNumber
    >
  </mat-form-field>

  <div class="kyc-content-holder column">
    <p class="select-title">Var kommer ditt sparade kapital ifrån?</p>
    @for (option of savingsFrom; track $index) {
      <mat-checkbox
        color="primary"
        (change)="onCheckboxChange($index)">
        {{ option.label }}
      </mat-checkbox>
    }
  </div>

  <p>Hur mycket uppskattar du att ditt årliga sparande i banken kommer att uppgå
    till? (Sparande på konton, i aktier/fonder och försäkringar.)</p>
  <mat-form-field>
    <mat-label>Totalt sparande i SEK per år</mat-label>
    <input matInput formControlName="savingsYearly" spbFormatNumber
    >
  </mat-form-field>

  <p>Vad sparar du till?</p>
  <mat-form-field>
    <mat-label>Vad sparar du till</mat-label>
    <input matInput formControlName="savingsGoal"
    >
  </mat-form-field>

</form>
