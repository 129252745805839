<form [formGroup]="birthForm">
  <h3>Ange ditt födelseland och din födelseort</h3>
  <div class="form-field-group"
       [class.small-screen]="orientationService.isSmallScreen$()"
  >

    <mat-form-field>
      <mat-label>Födelseland</mat-label>
      <mat-select
        formControlName="birthCountry"
        ngDefaultControl>
        @for (country of countryList; track $index) {
          <mat-option
            [ngClass]="{'separator-after': $index === 0}"
            [value]="country.name">{{ country.name }}
          </mat-option>
        }
      </mat-select>
      <mat-error>Vänligen gör ett val</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Födelseort</mat-label>
      <input formControlName="birthCity" matInput type="text">
      <mat-error>Vänligen ange födelseort</mat-error>
    </mat-form-field>
  </div>

  <h3>Medborgarskap</h3>
  <p>Om du har dubbelt medborgarskap, klicka på '+ Lägg till fler'
    nedan</p>
  <p>I vilket land är du medborgare?</p>
  <div formArrayName="citizenship">
    @for (control of citizenshipControls; track $index) {
      <div class="select-country">
        <mat-form-field>
          <mat-label>Välj land</mat-label>
          <mat-select [formControlName]="$index">
            @for (country of countryList; track $index) {
              <mat-option
                [ngClass]="{'separator-after': $index === 0}"
                [value]="country.name">{{ country.name }}
              </mat-option>
            }
          </mat-select>
          <mat-error>Vänligen gör ett val</mat-error>
        </mat-form-field>
        @if ($index !== 0 && $index === citizenshipControls.length - 1) {
          <button mat-button (click)="removeCitizenship($index)">- Ta bort
          </button>
        }
      </div>

    }
    <button mat-button (click)="addCitizenship()">
      <div class="centered-container">
        <span class="material-symbols-outlined">add</span>
        <span>Lägg till fler</span>
      </div>
    </button>
  </div>
</form>