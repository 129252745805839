import {registerLocaleData} from '@angular/common'
import {HttpClientModule} from '@angular/common/http'
import localeFrExtra from '@angular/common/locales/extra/fr'
import localeFr from '@angular/common/locales/fr'
import {NgModule} from '@angular/core'
import {ReactiveFormsModule} from '@angular/forms'
import {MatButtonModule} from '@angular/material/button'
import {MatCheckbox} from '@angular/material/checkbox'
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS, MatError,
  MatFormField,
  MatLabel, MatSuffix
} from '@angular/material/form-field'
import {MatIconModule} from '@angular/material/icon'
import {MatInput} from '@angular/material/input'
import {MatMenuModule} from '@angular/material/menu'
import {MatProgressBar} from '@angular/material/progress-bar'
import {MatRadioButton, MatRadioGroup} from '@angular/material/radio'
import {MatOption, MatSelect} from '@angular/material/select'
import {MatStep, MatStepLabel, MatStepper} from '@angular/material/stepper'
import {BrowserModule} from '@angular/platform-browser'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {BankIdModule} from '@sparbanken-syd/sparbanken-syd-bankid'
import {ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'
import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {HeaderComponent} from './components/header/header.component'
import {
  NextButtonComponent
} from './components/next-button/next-button.component'
import {PostalCodeFormatterDirective} from './directives/PostalCodeFormatter'
import {FormComponent} from './home/0-form/form.component'
import {ApplicantsComponent} from './home/1-applicants/applicants.component'
import {WelcomeComponent} from './home/2-welcome/welcome.component'
import {KycComponent} from './home/3-kyc/kyc.component'
import {SummaryComponent} from './home/4-summary/summary.component'
import {CompleteComponent} from './home/5-complete/complete.component'
import {HomeComponent} from './home/home.component'
import {BirthComponent} from './home/3-kyc/birth/birth.component'
import {
  PrimaryResidenceComponent
} from './home/3-kyc/primary-residence/primary-residence.component'
import {GreenCardComponent} from './home/3-kyc/green-card/green-card.component'
import {
  AmericanCitizenshipComponent
} from './home/3-kyc/american-citizenship/american-citizenship.component'
import {PepComponent} from './home/3-kyc/pep/pep.component'
import {
  TaxResidencyComponent
} from './home/3-kyc/tax-residency/tax-residency.component'
import {
  BankRelationshipComponent
} from './home/3-kyc/bank-relationship/bank-relationship.component'
import {IncomeComponent} from './home/3-kyc/income/income.component';
import { SavingsComponent } from './home/3-kyc/savings/savings.component'

registerLocaleData(localeFr, 'fr', localeFrExtra)

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    ApplicantsComponent,
    KycComponent,
    SummaryComponent,
    FormComponent,
    WelcomeComponent,
    CompleteComponent,
    BirthComponent,
    PrimaryResidenceComponent,
    GreenCardComponent,
    AmericanCitizenshipComponent,
    PepComponent,
    TaxResidencyComponent,
    BankRelationshipComponent,
    IncomeComponent,
    SavingsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    ThemeModule,
    MatStepper,
    MatStep,
    MatLabel,
    MatFormField,
    MatInput,
    ReactiveFormsModule,
    NextButtonComponent,
    BankIdModule,
    MatStepLabel,
    MatCheckbox,
    MatProgressBar,
    MatSelect,
    MatOption,
    MatSuffix,
    MatError,
    PostalCodeFormatterDirective,
    MatRadioGroup,
    MatRadioButton
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        floatLabel: 'always',
        hideRequiredMarker: true,
        appearance: 'outline'
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
