<form [formGroup]="taxResidencyForm">
  <h3>Skatterättslig hemvist</h3>
  <p>Skatterättslig hemvist är det land där en person betalar skatt och
    deklarerar sin inkomst, vanligtvis där personen bor eller arbetar och har
    skatteplikt enligt nationell lagstiftning.</p>
  <p>En person kan ha skatterättslig hemvist i flera länder.</p>

  <p class="select-title">Har du skatterättslig hemvist i andra länder,
    förutom Sverige?</p>
  <mat-radio-group
    color="primary"
    class="column"
    formControlName="taxResidencyNotSwedish">
    <mat-radio-button [value]="true">Ja</mat-radio-button>
    <mat-radio-button [value]="false">Nej</mat-radio-button>
  </mat-radio-group>
  <p>
    <strong>Kommentar: Givetvis går det att ha två frågor</strong>
    istället för ovanstående, hur ska dessa isåfall utformas?
  </p>
  @if (taxResidencyForm.controls.taxResidencyNotSwedish.value) {
    <p>Banken har skyldighet att fråga om uppgifter till följd av avtal om
      informationsinhämtning gällande FATCA/CRS. Läs mer om detta <a
        href="https://www.swedishbankers.se/media/3531/1710_crs.pdf"
        target="_blank">här.</a> Läs mer om respektive lands
      skatteregistreringsnummer/TIN <a
        href="https://www.oecd.org/tax/automatic-exchange/crs-implementation-and-assistance/tax-identification-numbers/#d.en.347759"
        target="_blank">här.</a></p>


    <p>Vänligen ange i vilket land/länder du har skatterättslig hemvist samt
      skatteregistreringsnummer/TIN och din relation till landet.</p>

    <div class="form-field-group"
         [class.small-screen]="orientationService.isSmallScreen$()"
    >

      <div formArrayName="taxResidencyCountries">
        @for (control of controls; track $index) {
          <div class="select-country">
            <mat-form-field>
              <mat-label>Välj land</mat-label>
              <mat-select [formControlName]="$index">
                @for (country of countryList; track $index) {
                  <mat-option
                    [value]="country.name">{{ country.name }}
                  </mat-option>
                }
              </mat-select>
              <mat-error>Vänligen gör ett val</mat-error>
            </mat-form-field>
            @if ($index !== 0 && $index === controls.length - 1) {
              <button mat-button (click)="removeControl($index)">- Ta bort
              </button>
            }
          </div>

        }
        @if (taxResidencyForm.controls.taxResidencyCountries.status === 'VALID') {
          <button mat-button (click)="addControl()" [style.margin-top.px]="-35">
            <div class="centered-container">
              <span class="material-symbols-outlined">add</span>
              <span>Lägg till fler</span>
            </div>
          </button>
        }
      </div>

    </div>
    <mat-form-field>
      <mat-label>Ange Skatteregistreringsnummer/TIN</mat-label>
      <input formControlName="taxResidencyTin" matInput type="text">
      @if (taxResidencyForm.controls.taxResidencyTin.touched) {
        <mat-error>Vänligen gör ett val</mat-error>
      }
    </mat-form-field>


    <p class="select-title">Din relation till landet.</p>
    <div class="column">
      @for (option of workingAbroadOptions; track $index) {
        <mat-checkbox
          color="primary"
          [checked]="option.value === taxResidencyForm.controls.taxResidencyRelation.value"
          (change)="onCheckboxChange(option.value, 'taxResidencyRelation')">
          {{ option.label }}
        </mat-checkbox>
      }
    </div>

    <p>
      }
</form>