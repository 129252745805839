import {Component, Input} from '@angular/core'
import {MatStepperNext} from '@angular/material/stepper'

@Component({
  selector: 'spb-next-button',
  standalone: true,
  imports: [
    MatStepperNext
  ],
  templateUrl: './next-button.component.html'
})
export class NextButtonComponent {
  @Input() valid!: boolean
}
