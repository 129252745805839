import {Directive, HostListener} from '@angular/core'
import {NgControl} from '@angular/forms'

@Directive({
  standalone: true,
  selector: '[spbPostalCodeFormatter]'
})
export class PostalCodeFormatterDirective {

  constructor(private ngControl: NgControl) {
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    // Remove any non-numeric characters
    const numericValue = value.replace(/\D/g, '')

    // Limit the input to 5 digits
    const limitedValue = numericValue.slice(0, 5)

    // Format the postal code as 'xxx xx' for display
    const formattedValue = limitedValue.replace(/^(\d{3})(\d{2})/, '$1 $2')

    // Update the form control value with the formatted value for display
    this.ngControl.control?.setValue(formattedValue, {
      emitEvent: false, // Prevent circular event loop
      emitModelToViewChange: true,
      emitViewToModelChange: false
    })

    // Update the form control value with the unformatted value for form submission
    this.ngControl.control?.setValue(limitedValue, {
      emitEvent: false, // Prevent circular event loop
      emitModelToViewChange: false,
      emitViewToModelChange: true
    })
  }
}
