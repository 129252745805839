import {Component} from '@angular/core'
import {SavingsAccountService} from '../../services/savings-account.service'
import {environment} from '../../../environments/environment'

@Component({
  selector: 'spb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  public prod = environment.production

  constructor(
    public savingsAccountService: SavingsAccountService
  ) {
  }
}
