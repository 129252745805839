import {Component, Input} from '@angular/core'
import {AbstractControl, FormGroup} from '@angular/forms'
import {OrientationService} from '../../services/orientation.service'
import {SavingsAccountService} from '../../services/savings-account.service'
import {KycTypeMap, KycType} from '../../types'

@Component({
  selector: 'spb-kyc',
  templateUrl: './kyc.component.html',
  styleUrls: ['./kyc.component.scss']
})
export class KycComponent {
  @Input({required: true}) kycForm!: FormGroup
  public currentStep = 0 // TODO: STANDARD 0!!!
  public totalSteps = 9
  protected KycType = KycType


  constructor(
    protected savingsAccountService: SavingsAccountService,
    protected orientationService: OrientationService
  ) {
  }

  public nextStep() {
    if (this.currentStep < this.totalSteps) {
      this.currentStep++
    }
  }

  public previousStep() {
    if (this.currentStep > 0) {
      this.currentStep--
    }
  }

  public disabledButton() {
    // Use KycTypeMap to convert the currentStep enum value to its string representation
    const stepName = KycTypeMap.get(this.currentStep)

    // Access the form control based on the string representation
    const validationFunction = () => !this.kycForm.controls[`${stepName}Form`].valid

    // Return the result of the validation function (true or false)
    return validationFunction()
  }

  protected getFormGroup(control: AbstractControl) {
    return control as FormGroup
  }
}
