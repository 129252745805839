import {Component, Input} from '@angular/core'
import {FormGroup} from '@angular/forms'

@Component({
  selector: 'spb-savings',
  templateUrl: './savings.component.html',
  styleUrl: '../kyc.component.scss'
})
export class SavingsComponent {
  @Input({required: true}) savingsForm!: FormGroup

  public savingsFrom = [
    {value: 'income', label: 'Inkomst med löpande sparande', checked: false},
    {value: 'inheritanceOrGift', label: 'Arv eller gåva', checked: false},
    {value: 'saleOfHouse', label: 'Försäljning av bostad', checked: false},
    {value: 'otherSale', label: 'Annan försäljning (företag eller andra tillgångar)', checked: false},
    {value: 'lotteryWin', label: 'Spel eller lotterivinst', checked: false}
  ]

  public onCheckboxChange(index: number) {
    this.savingsFrom[index].checked = !this.savingsFrom[index].checked
  }
}
