import {Component, OnInit} from '@angular/core'
import {environment} from '../../environments/environment'
import {SavingsAccountService} from '../services/savings-account.service'

@Component({
  selector: 'spb-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit {
  protected readonly authServiceUrl = environment.authServiceUrl

  constructor(protected savingsAccountService: SavingsAccountService) {
  }

  public ngOnInit() {
    this.savingsAccountService.applicant$.set({
      countryCode: '+46', email: '', phone: '',
      name: '',
      personalNumber: ''
    })
  }

  /**
   * Cant use helperService to do this, because Ä becomes Ã¤
   * @param token
   */
  public setToken(token: string) {
    const binaryString = atob(token.split('.')[1])
    const bytes = new Uint8Array(binaryString.length)

    for (let i = 0; i < bytes.length; i++) {
      bytes[i] = binaryString.charCodeAt(i)
    }

    const decodedPayload = new TextDecoder('utf-8').decode(bytes)
    const user = JSON.parse(decodedPayload)
    const name = user.name
      .split(' ').map((sub: string) => sub.substring(0, 1)
        .toUpperCase() + sub.substring(1).toLowerCase()).join(' ')

    this.savingsAccountService.applicant$.set({
      countryCode: '+46', email: '', phone: '',
      name,
      personalNumber: user.sub
    })
  }
}
